import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import NewsHeader from "../../components/NewsHeader";
import SleepyNewsAnchor from "../../components/SleepyNewsAnchor";

const NewsPage = () => (
	<Layout>
		<Seo title="August 2022" />
		<NewsHeader />
		<h2>August 2022</h2>

		<h3 className="blogdate">
			Monday, August 8, 2022
		</h3>

		<h4 className="blogitemtitle">
			<SleepyNewsAnchor num="1">Celebrating a year of Summer Grace with a new video</SleepyNewsAnchor>
		</h4>
		<div className="blogitembody">
			<p>
				I wanted to celebrate a song which came out about a year ago, called 🍒 Summer Grace 🍒.
				A video of this song has just gone live on Youtube.
				I filmed it with my partner Jane on a trip to Pirenópolis, Goiás, in Brazil, just a week or two after the song was released.
				You'll get to see a bit of what winter (the dry season) is like in our part of the world.
				For a little glimpse of our lives, head on over and have a peek!
			</p>
			<p>
				<Link to="/music/06/">https://www.jesserivest.com/music/06/</Link> (see Extras at the bottom)
			</p>
		</div>

		<p>
		<Link to="/news/">Go back to the news index page</Link>
		</p>
	</Layout>
);

export default NewsPage;
